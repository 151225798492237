
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { CommonModule } from '@angular/common';
import { IndexComponent } from '../index/index.component';

import { LoginComponent } from '../LOGIN/login/login.component';








const routes: Routes = [
  /*{
    path: '',
    component: DashboardComponent,
  },*/
  {
    path: 'inicio',
    component: IndexComponent
  },

  {

    path: 'login',
    component: LoginComponent
  },


];

@NgModule({
  imports: [CommonModule, RouterModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})

export class DashboardRoutingModule { }
