import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from 'src/services/general.service';

declare var svg:any;
@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
data:any;
bann: any;
boton:any;
  constructor(private router:Router, private service: GeneralService) { }

  ngOnInit(): void {


  }

  }
